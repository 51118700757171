import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import { Navbar } from "./Navbar"

import BG from "gatsby-background-image"
import { Section } from "./Section"
import { GooglePlayButton } from "./GooglePlayButton"

const BackgroundImage = styled(BG)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const BaseHeader = styled.header``

const query = graphql`
  {
    image: imageSharp(fluid: { originalName: { eq: "header_bg.png" } }) {
      id
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

const FrontpageHeader = () => {
  const { image } = useStaticQuery(query)
  return (
    <BackgroundImage
      fluid={[
        `linear-gradient(0deg, rgba(106, 97, 90, 0.65), rgba(106, 97, 90, 0.65))`,
        image.fluid,
      ]}
      as="header"
    >
      <Navbar />
      <Section
        title="Ikke tekst, bare Kjør"
        text="Kjør er en app som raskt og enkelt hjelper deg med å holde fokus på veien mens du kjører."
        phoneSrc="1_header.png"
        header
      >
        <GooglePlayButton />
      </Section>
    </BackgroundImage>
  )
}

export const Header = ({ frontpage }) => {
  if (frontpage) return <FrontpageHeader />
  return (
    <BaseHeader>
      <Navbar style={{ backgroundColor: "#4a443f" }} />
    </BaseHeader>
  )
}

import React from "react"

export const Burger = ({ color = "white", ...rest }) => {
  return (
    <svg
      width="36"
      height="29"
      viewBox="0 0 36 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect width="36" height="5" rx="1" fill={color} />
      <rect y="8" width="36" height="5" rx="1" fill={color} />
      <rect y="16" width="36" height="5" rx="1" fill={color} />
      <path
        d="M0 25C0 24.4477 0.447715 24 1 24H35C35.5523 24 36 24.4477 36 25V28C36 28.5523 35.5523 29 35 29H1C0.447716 29 0 28.5523 0 28V25Z"
        fill={color}
      />
    </svg>
  )
}

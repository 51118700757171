import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import image from "../images/phones/4_log.png"

const Link = styled.a`
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  margin: 0 auto;
  @media screen and (min-width: 800px) {
    margin: 0;
  }
`

export const GooglePlayButton = () => {
  const data = useStaticQuery(query)
  return (
    <Link href="https://play.google.com/store/apps/details?id=no.ways.justdrive">
      <Img fixed={data.placeholderImage.childImageSharp.fixed} />
    </Link>
  )
}

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "google_play_badge.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

import React, { useState } from "react"
import styled, { css } from "styled-components"
//import logo from "../images/logo_white.png"
import { Link } from "gatsby"
import { Logo } from "./svg/Logo"
import { Cross } from "./svg/Cross"
import { Burger } from "./svg/Burger"

const Wrapper = styled.nav`
  color: white;
  width: 100%;
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Links = styled.div`
  display: none;
  ${props =>
    props.open &&
    css`
      position: absolute;
      z-index: 100;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: rgb(74, 68, 63);

      ${MenuLink} {
        font-size: 30px;
        padding: 24px 0;
      }
    `}
  @media screen and (min-width: 800px) {
    position: relative;
    display: flex;
    flex-direction: row;
  }
`

const MenuLink = styled(Link)`
  display: block;
  color: inherit;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 500;
  min-width: 100px;
  @media screen and (min-width: 1000px) {
    min-width: 150px;
  }
`

const Button = styled.button`
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  right: 48px;
  width: 36px;
  @media screen and (min-width: 800px) {
    display: none;
  }
`

const BurgerButton = ({ open, setOpen }) => {
  if (open) {
    return <Button as={Cross} onClick={() => setOpen(false)} />
  } else {
    return <Button as={Burger} onClick={() => setOpen(true)} />
  }
}

export const Navbar = ({ style }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <Wrapper style={style}>
      <Link to={"/"}>
        <Logo color="white" strikeColor="white" width="129px" height="38px" />
      </Link>
      <Links open={menuOpen}>
        <MenuLink to="https://play.google.com/store/apps/details?id=no.ways.justdrive">
          Last ned
        </MenuLink>
        <MenuLink to={"/om-oss"}>Om oss</MenuLink>
        <MenuLink to={"/hjelp"}>Hjelp</MenuLink>
        <MenuLink to={"/personvern"}>Personvern</MenuLink>
      </Links>
      <BurgerButton open={menuOpen} setOpen={setMenuOpen} />
    </Wrapper>
  )
}

import React from "react"
import styled, { css } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { MainTitle } from "./styled/MainTitle"
import { Title } from "./styled/Title"
import { Text } from "./styled/Text"
import Img from "gatsby-image"

const SectionColored = styled.section`
  flex: 1;
  min-height: 100%;
  padding: 96px 0;
  color: ${props => (props.header ? "white" : "#737373")};
  background-color: ${props => (props.header ? "transparent" : "#f1edea")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Contents = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
  /*
  > * {
    @media screen and (min-width: 600px) {
      &:first-child {
        margin-left: 80px;
      }
    }
  }*/
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const PhoneContainer = styled.div`
  flex: 1;
  display: flex;

  @media screen and (min-width: 800px) {
    ${props =>
      props.left &&
      css`
        order: -1;
      `}
  }
`

const PhoneImage = styled(Img)`
  max-width: ${props => props.maxWidth}px;
  margin: 0 auto;
`

const query = graphql`
  {
    allFile(filter: { sourceInstanceName: { eq: "phones" } }) {
      images: nodes {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
          }
          original {
            width
          }
        }
        id
        relativePath
      }
    }
  }
`

export const Section = ({
  title,
  text,
  phoneSrc,
  imageAlt,
  children,
  header = false,
  left = false,
  ...rest
}) => {
  const data = useStaticQuery(query)
  const images = data?.allFile?.images ?? []
  const image = images.find(image => image.relativePath === phoneSrc)
  if (!image) console.error("Image not found, you did something wrong!")
  const TitleComponent = header ? MainTitle : Title

  return (
    <SectionColored header={header} {...rest}>
      <Contents>
        <Container>
          <TitleComponent>{title}</TitleComponent>
          <Text>{text}</Text>
          {children}
        </Container>
        <PhoneContainer left={left}>
          {image && (
            <PhoneImage
              fixed={image.childImageSharp.fixed}
              alt={imageAlt}
              maxWidth={image.childImageSharp.original.width}
            />
          )}
        </PhoneContainer>
      </Contents>
    </SectionColored>
  )
}

export const SectionTransparent = styled(Section)`
  background-color: transparent;
`

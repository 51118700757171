import React from "react"

export const Logo = ({
  color = "#F1ECE9",
  strikeColor = "#EF6C3D",
  width = "209",
  height = "64",
  alt = "Kjør",
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 209 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>{alt}</title>
      <g clip-path="url(#clip0)">
        <path
          d="M44.0418 1.47217H26.7928L14.8847 23.6903V1.47217H0V62.8869H14.8847V45.5463L18.3006 39.857L27.5815 62.8869H44.9187L29.069 26.6696L44.0418 1.47217Z"
          fill={color}
        />
        <path
          d="M67.2148 1.47217V41.1219C67.2148 46.0061 65.2015 48.5324 60.9986 48.5324C57.1452 48.5324 53.4681 46.9071 50.4033 43.9261V60.6318C53.1187 62.5299 58.6343 63.884 63.363 63.884C76.5887 63.884 82.0995 56.0254 82.0995 41.5766V1.47217H67.2148Z"
          fill={color}
        />
        <path
          d="M196.654 35.1597C202.082 33.3543 206.724 28.3859 206.724 19.7172C206.724 7.97491 198.493 1.47217 186.585 1.47217H164.432V62.8869H179.317V41.3913H180.806C187.898 41.3913 191.746 50.513 193.501 62.8869H209C207.95 50.4238 204.36 39.4107 196.654 35.1597ZM186.497 26.5804H179.312V16.2848H186.497C189.387 16.2848 191.395 18.0903 191.395 21.4317C191.395 24.7732 189.387 26.5804 186.497 26.5804Z"
          fill={color}
        />
        <path
          d="M148.841 14.2888L133.156 30.4673V49.4248C133.155 50.2178 132.85 50.9782 132.306 51.5388C131.762 52.0994 131.025 52.4143 130.256 52.4143H116.247C115.686 52.414 115.138 52.2461 114.668 51.9311C114.198 51.616 113.827 51.1673 113.6 50.6391L105.996 58.4825C111.09 62.0359 117.101 63.9304 123.251 63.9208C140.295 63.9208 154.111 49.669 154.111 32.0892C154.121 25.7454 152.285 19.5444 148.841 14.2888Z"
          fill={color}
        />
        <path
          d="M113.348 14.316C113.348 13.5229 113.653 12.7624 114.197 12.2015C114.74 11.6406 115.478 11.3252 116.246 11.3248H130.256C130.873 11.3248 131.474 11.5279 131.971 11.9044C132.469 12.2809 132.837 12.8112 133.022 13.4183L140.508 5.69785C135.414 2.14304 129.402 0.247891 123.251 0.257852C106.208 0.257852 92.391 14.5096 92.391 32.0894C92.3814 38.433 94.2181 44.6335 97.6634 49.8882L113.348 33.7197V14.316Z"
          fill={color}
        />
        <path
          d="M133.156 27.1932L147.5 12.3974L154.013 5.67906L148.855 0.358643L142.342 7.07696L133.156 16.5523L113.348 36.9835L99.004 51.7793L92.4662 58.5229L97.6242 63.8433L104.162 57.0997L113.348 47.6243L133.156 27.1932Z"
          fill={strikeColor}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="209" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

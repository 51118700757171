import React from "react"

export const Cross = ({ color = "white", ...rest }) => {
  return (
    <svg
      width="29"
      height="30"
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        y="25.4558"
        width="36"
        height="5"
        rx="1"
        transform="rotate(-45 0 25.4558)"
        fill={color}
      />
      <path
        d="M2.82842 1.70711C3.21894 1.31658 3.8521 1.31658 4.24263 1.70711L28.2843 25.7487C28.6748 26.1393 28.6748 26.7724 28.2843 27.163L26.1629 29.2843C25.7724 29.6748 25.1392 29.6748 24.7487 29.2843L0.707096 5.24264C0.316571 4.85212 0.316571 4.21895 0.707095 3.82843L2.82842 1.70711Z"
        fill={color}
      />
    </svg>
  )
}
